import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import React, { useLayoutEffect, lazy, Suspense} from 'react'

import './App.css';
import './Responsive.css';
import "../node_modules/slick-carousel/slick/slick.css"
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/slick-carousel/slick.jquery.json"


const Navbar = lazy(() => import('./components/Navbar'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const Home = lazy(() => import('./components/Home'));
const About = lazy(() => import('./components/About'));
const Gym = lazy(() => import('./components/Gym'));
const Pricing = lazy(() => import('./components/Pricing'));
const Contact = lazy(() => import('./components/Contact'));
const Footer = lazy(() => import('./components/Footer'));
const Whatsapp = lazy(() => import('./components/Whatsapp'));
const Login = lazy(() => import('./components/Login'));
const GymDetailed = lazy(() => import('./components/GymDetailed'));
const UserInterface = lazy(() => import('./components/UserInterface'));
const ControlPanel = lazy(() => import('./components/ControlPanel'));
const OperatorPanel = lazy(() => import('./components/OperatorPanel'));
const Faq = lazy(() => import('./components/Faq'));
const Rating = lazy(() => import('./components/Rating'));
const Payment = lazy(() => import('./components/Payment'));
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions'));
const PageNotFound = lazy(() => import('./components/PageNotFound'));
const LoadingPage = lazy(() => import('./components/LoadingPage'));



function App() {

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }


  return (
    <div className='container'>
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <Wrapper>
            <Navbar />
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/gym' element={<Gym />} />
              <Route path='/pricing' element={<Pricing />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/login' element={<Login />} />
              <Route path='/GymDetailed/:id' element={<GymDetailed />} />
              <Route path='/GymDetailed/:id/Rating' element={<Rating />} />
              <Route path='/UserInterface' element={<UserInterface />} />
              <Route path='/ControlPanel' element={<ControlPanel />} />
              <Route path='/OperatorPanel' element={<OperatorPanel />} />
              <Route path='/Faq' element={<Faq />} />
              <Route path='/Payment' element={<Payment />} />
              <Route path='/TermsAndConditions' element={<TermsAndConditions />} />
              <Route path='/az/home/privacyPolicy' element={<PrivacyPolicy />} />
              <Route path='/en/home/privacyPolicy' element={<PrivacyPolicy />} />
              <Route path='/ru/home/privacyPolicy' element={<PrivacyPolicy />} />
              <Route path='*' element={<PageNotFound />} />
            </Routes>
            <Footer />
            <Whatsapp />
          </Wrapper>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
